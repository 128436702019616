import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import DialogSlide from '../Dialog/index'
import * as FlexmonsterReact from 'react-flexmonster';
import * as WebDataRocksReact from 'react-webdatarocks';
import { Storage } from 'aws-amplify';
import Loader from "../Loader/index";
import 'flexmonster/flexmonster.css';
import 'webdatarocks/webdatarocks.css'
import Chart from '../../components/Chart/index'
import Carousel from 'react-material-ui-carousel'
import SplitterLayout from 'react-splitter-layout'
import { Paper } from '@material-ui/core';

const useStyles = makeStyles((theme) => (style));

// Para cambiar entre webdatarocks y flexmonster hay que:
// - Substituir el objeto WebDataRocksReact por FlexmonsterReact
// - Substituir las referencias webdatarocks por flexmonster
// - Substituir la refencia wdr del custom toolbar por fm

export default function Flexmonster(props) {

  const classes = useStyles();
  const ref = React.useRef();
  const [openDialogSave, setOpenDialogSave] =React.useState(false)
  const [showLoader, setShowLoader] = React.useState(false);
  const [dataChart, setDataChart] = React.useState()
  const [dataPie, setDataPie] = React.useState()
  const [optionsChart, setOptionsChart] = React.useState()

  React.useEffect(() =>  {
      handleChartOptions()
      if (props.data && props.data.saved===true){ //Report salvado
          ref.current.webdatarocks.setReport(props.data)
      } else if (props.data) { //Default
        ref.current.webdatarocks.setReport({ 
          dataSource: { 
            data: props.data?props.data:{}
          }, 
          options: {
            grid:{
              type: props.gridType?props.gridType:'classic',
              showTotals: 'off'
            }
          },
          formats: [{
            name: "",
            thousandsSeparator: ".",
            decimalSeparator: ",",
            decimalPlaces: 2,
            nullValue: "",
            textAlign: "right",
            isPercent: false
          }] 
      })
  }}, [props.data]);

  const handleConnection = () => {
    
  }

  const handleExport = () => {
    
  }

  const handleOpen = () => {
    
  }


  const handleSave = async (data) => {
    setOpenDialogSave(false)
    setShowLoader(true)
    var report = ref.current.webdatarocks.getReport()
    report.chartSaved = data.form.chart
    report.saved = true

    await Storage.put(`Reports/${data.form.report}.json`, report, {
      level: 'public',
      progressCallback(progress) {
          console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
      },
    })
    props.onCreate()
    setShowLoader(false)
  }

  const handleDialogSave = async () => {
    setOpenDialogSave(true)
  }

  const closeDialogSave = async () => {
    setOpenDialogSave(false)
  }
  

  function customizeToolbar(toolbar) {

    if (toolbar){

    let tabs = toolbar.getTabs(); 

    toolbar.getTabs = function() {
      
      tabs = tabs.filter(tab => 
        tab.id != "wdr-tab-connect" &&
        tab.id != "wdr-tab-open" &&
        tab.id != "wdr-tab-fields" &&
        tab.id != "wdr-tab-save" &&
        tab.id != "wdr-tab-charts" &&
        tab.id != "wdr-tab-grid" )

      if (props.connection && props.connection === true) {
      var connect = {
        id: "wdr-tab-connect",
        icon: this.icons.connect,
        handler: handleConnection
      }
      tabs.push(connect);
      }

      if (props.export && props.export === true) {
        var connect = {
          id: "wdr-tab-export",
          icon: this.icons.export,
          handler: handleExport
        }
        tabs.push(connect);
        }

       
          var connect = {
            id: "wdr-tab-save",
            icon: this.icons.save,
            handler: handleDialogSave
          }
          tabs.push(connect);
        

        if (props.open && props.open === true) {
          var connect = {
            id: "wdr-tab-open",
            icon: this.icons.open,
            handler: handleOpen
          }
          tabs.push(connect);
        }

      return tabs;
    }

  }

}

  const onReportComplete = () => {
    //createChart() Descomentar cuando haya licencia
  }

  const createChart = () => {
    const FmRef = ref.current.webdatarocks;
    FmRef.getData(
      {},
      drawChart,
      updateChart
  ); 
  }

  const drawChart = (rawData) => {
    if (rawData){
    parseData(rawData)
    }
  }

  const updateChart = (rawData) => {

  }

  const parseData = (rawData) => {

    var dataAux = []
    var dataAux2 = []
    var dataAux3 = []
    var labelAux = []

    for (var i = 0; i < rawData.data.length; i++){
      if (i < 100){
      //Ratio1
      if (rawData.data[i].r0){
        var value = Math.round((rawData.data[i].v0 + Number.EPSILON) * 100) / 100 
        dataAux.push(value)
        labelAux.push(rawData.data[i].r0)
      }

      //Ratio2
      if (rawData.data[i].r0 && rawData.data[i].v1){
        var value2 = Math.round((rawData.data[i].v1 + Number.EPSILON) * 100) / 100 
        dataAux2.push(value2)
      }

      //Ratio3
      if (rawData.data[i].r0 && rawData.data[i].v2){
        var value3 = Math.round((rawData.data[i].v2 + Number.EPSILON) * 100) / 100 
        dataAux3.push(value3)
      }      
     }
    } 

    if (dataAux.length > 0){

        const dataParsed = []
        var data1 = {
          name: rawData.meta.v0Name,
          data: dataAux
        }
        dataParsed.push(data1)

        if (dataAux2.length > 0) {
        var data2 = {
          name: rawData.meta.v1Name,
          data: dataAux2
        }
        dataParsed.push(data2)
        }

        if (dataAux3.length > 0) {
          var data3 = {
            name: rawData.meta.v2Name,
            data: dataAux3
          }
          dataParsed.push(data3)
        }
      var dataPie = dataParsed[0].data
      setDataChart(dataParsed)
      setDataPie(dataPie)

      const options = {
        xaxis: labelAux,
        xaxisText: rawData.meta.r0Name,
        yaxisText: rawData.meta.v0Name
      }

      handleChartOptions(options)
    }
  }

  const handleChartOptions = (options) =>{

    var config = {
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: []
          },
          export: {
            svg: {
              filename: 'ribosomu_chart_export',
            },
            png: {
              filename: 'ribosomu_chart_export',
            }
          },
          autoSelected: 'zoom' 
        },
      },
      dataLabels: {
          enabled: options && options.dataLabels?options.dataLabels:true
      },
      title: {
          text: options && options.title?options.title:'',
          align: 'left'
      },
      //colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'],
      // xaxis: {
      //   categories: options && options.xaxis?options.xaxis:[],
      //   title: {
      //     text: options && options.xaxisText?options.xaxisText:'Dimension'
      //   }
      // },
      labels: options && options.xaxis?options.xaxis:[],
      yaxis: {
        title: {
          text: options && options.yaxisText?options.yaxisText:'Measure'
        }
      },
      markers: {
          hover: {
          sizeOffset: 4
          }
      }
    }

    setOptionsChart(config)
  }

  return (
    <div className = {classes.root}>
      <Loader show = {showLoader}/>
      <SplitterLayout secondaryInitialSize = {50} percentage = {true}> 
        <div className = {classes.pivot}>
          <Paper>
          <WebDataRocksReact.Pivot 
                ref={ref}
                toolbar={true}
                beforetoolbarcreated= {customizeToolbar}
                width="100%"
                height="593px"
               // licenseKey = 'Z7ZK-XDAB5Q-6N325P-0K0B35'
                reportcomplete={onReportComplete}
            />
            </Paper>
         </div>
         {dataChart?
         <div className = {classes.carousel}>
           <Paper>
        {!props.data.saved || props.data.save ===false?    
         <Carousel interval = {9999999999}>
          <div className = {classes.chart}>
            <Chart 
                options={optionsChart} 
                series={dataChart} 
                width= {'100%'}
                height={'100%'}
                type='line'/>
           </div>
           <div className = {classes.chart}>    
            <Chart 
                options={optionsChart} 
                series={dataPie} 
                width= {'100%'}
                height={'100%'}
                type='donut'/> 
             </div>
            <div className = {classes.chart}>    
            <Chart 
                options={optionsChart} 
                series={dataChart} 
                width= {'100%'}
                height={'100%'}
                type='bar'/> 
             </div>
            <div className = {classes.chart}>  
            <Chart 
                options={optionsChart} 
                series={dataChart} 
                width= {'100%'}
                height={'100%'}
                type='area'/>                 
          </div>  
          <div className = {classes.chart}>  
            <Chart 
                options={optionsChart} 
                series={dataChart} 
                width= {'100%'}
                height={'100%'}
                type='radar'/>                 
          </div>   
        </Carousel>:
           <div className = {classes.chart}>  
            <Chart 
                options={optionsChart} 
                series={dataChart} 
                width= {'100%'}
                height={'100%'}
                type={props.data.chartSaved}/>                 
          </div>}    
        </Paper>
        </div>:null}
        </SplitterLayout>
        <DialogSlide 
          open = {openDialogSave} 
          mode = 'formSaveReport' 
          onConfirm = {handleSave}
          onCancel = {closeDialogSave}
        />
              
    </div>
  )
}
