import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import { Storage } from 'aws-amplify';
import XLSX from 'xlsx'
import {TextField, Link, List, ListItem, ListItemText, Select, InputLabel, MenuItem, FormControl} from '@material-ui/core';

const useStyles = makeStyles((theme) => (style));

export default function ColumnList({file, dataProps, callback, close, empty, id, comment}) {

  const classes = useStyles();
  const [data, setData] =React.useState()
  const forceUpdate = React.useReducer(bool => !bool)[1];

  React.useEffect(() => {
     if (!empty){
        if (file){
          getObject(file) //Se pasa un fichero en data lake
        } 
        if (dataProps){
          parseData(dataProps)    //Se pasan los datos
        }
     } else {
       for (var i = 0; i < empty; i++){
        handleAdd() // Filas vacías = empty
      }
    }
    }, [dataProps, file]);

  const getObject = async (file) => {
     
      Storage.get(file.key, { 
          level: 'public', 
          download: true
      }).then(result =>{
              if (file.extension === 'csv'){  
                   readExcel(result.Body)
               } else if (file.extension === 'json'){ // Si es JSON, recuperamos el texto
                   result.Body.text().then(string => { 
                   string = String(string)
                   console.log(string)
                   parseData(JSON.parse(string))
                   });
               }
      })
      
  }

  const readExcel = (data) => {
    var oFile = data;
    var reader = new FileReader();
    reader.onload = function(e) {
        var data = e.target.result;
        var cfb = XLSX.read(data, {type: 'binary', sheetRows: 5});
        cfb.SheetNames.forEach(function(sheetName) {  
            var oJS = XLSX.utils.sheet_to_row_object_array(cfb.Sheets[sheetName]); 
            parseData(oJS)
        });
    };

    reader.readAsBinaryString(oFile);
}  

const parseData = (dataExcel) => {
    var keys = dataExcel[0]?Object.keys(dataExcel[0]):Object.keys(dataExcel)
    var columns = []
    for (var j = 0;j < keys.length ; j++){
      var column = {
        name: keys[j],
        example: dataExcel[0]?dataExcel[0][keys[j]]:dataExcel[keys[j]],
        type: dataExcel[0]?typeof dataExcel[0][keys[j]]:typeof dataExcel[keys[j]],
        comment: ''
      }
      column.type = column.type.toUpperCase()
      columns.push(column)
    }
    setData(columns)
};

const handleChangeType = (event) => {
   var newData = data
   for (var i = 0; i < newData.length; i++){
     if (newData[i].name === event.target.name){ 
       if(event.target.id !== undefined){
        newData[i].comment = event.target.value
       } else {
      newData[i].type = event.target.value
       }
     }
   }
   setData(newData)
   forceUpdate()
   callback(newData, id)
}

const handleChangeName = (event) => {
  var newData = data
  for (var i = 0; i < newData.length; i++){
    if (newData[i].name === event.target.name){ 
      if(event.target.id !== undefined){
       newData[i].name = event.target.value
      } else {
     newData[i].name = event.target.value
      }
    }
  }
  setData(newData)
  forceUpdate()
  callback(newData, id)
}


const handleBack = (event) => {
  close()
}

const handleAdd = () => {

  var rows = []

  if (data) {
    rows = data
  }

  var row = {
    name: '',
    type: '',
    comment: ''
  }

  rows.push(row)
  setData(rows)
  forceUpdate()  
}

  return (
      <div className ={classes.root}>
        {!dataProps?
        <Link
              component="button"
              variant="body2"
              onClick={!empty?handleBack:null} //Descomentar si queremos n particiones
              className={classes.link}
            >
              {empty?'Add':'Back'}
        </Link>:null}
        <List>
        {data?data.map((item) => (
          <ListItem>
                  {!empty?
                    <ListItemText
                            primary={item.name}
                            secondary={item.example}
                            className={classes.text}
                    ></ListItemText>:
                    <TextField 
                            className={classes.nameInput} 
                            label="Name" 
                            id="name"
                            name={item.name}
                            value={item.name}
                            onChange={handleChangeName}               
                    />}
                  {comment?  
                  <TextField 
                       className={classes.comment} 
                       label="Comment" 
                       id="comment"
                       name={item.name}
                       value={item.comment}
                       onChange={handleChangeType}               
               />:null}
              <FormControl className={classes.formControl}>
                  <InputLabel id={item.name + '-label'}>Type</InputLabel>
                  <Select
                    labelId={item.name + '-label'}
                    name={item.name}
                    value={item.type}
                    onChange={handleChangeType}
                  >
                    <MenuItem value={'STRING'}>STRING</MenuItem>
                    <MenuItem value={'INT'}>INTEGER</MenuItem>
                    <MenuItem value={'DOUBLE'}>DOUBLE</MenuItem>
                    <MenuItem value={'DATE'}>DATE (YYYY-MM-DD)</MenuItem>
                    <MenuItem value={'TIMESTAMP'}>TIMESTAMP (yyyy-MM-dd HH:mm:ss)</MenuItem>
                    <MenuItem value={'BOOLEAN'}>BOOLEAN</MenuItem>
                  </Select>
              </FormControl>
          </ListItem>
        )):<div></div>}
        </List>
      </div>
  )
}
