import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/ext-beautify";
import "ace-builds/src-noconflict/ext-language_tools";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-json";
import "ace-builds/src-noconflict/mode-html";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/mode-sql";
import "ace-builds/src-noconflict/theme-solarized_dark";
import "ace-builds/src-noconflict/theme-solarized_light";
import "ace-builds/src-noconflict/theme-xcode";
import Fab from '@material-ui/core/Fab';
import {SaveOutlined} from '@material-ui/icons/';
import DialogSlide from '../Dialog/index'
import { Storage } from 'aws-amplify';
import Loader from "../Loader/index";

const useStyles = makeStyles((theme) => (style));

export default function Editor(props) {

    const classes = useStyles();
    const [showSave, setShowSave] = React.useState(false);
    const [code, setCode] =React.useState('')
    const [openDialog, setOpenDialog] =React.useState(false)
    const [showLoader, setShowLoader] = React.useState(false);

    React.useEffect(() => {
        var codeAux
       if (props.mode==='json'){
         codeAux = props.object?JSON.stringify(JSON.parse(props.object), null, '\t'):''
        } else {
         codeAux = props.object   
        }
       setCode(codeAux)
       if (props.default === 'save'){
         setShowSave(true)
       }

    }, [props.object]);

    const onChange = (newValue) => {
        if (props.default === 'save'){
        setShowSave(true)
        }
        setCode(newValue)
        if (props.callback){
        props.callback(newValue)
        }
    }

    const handleSave = async () => {
        setOpenDialog(true)
    }  

    const save = async (save) => {
        setOpenDialog(false)
        setShowLoader(true)    
        await Storage.put(props.path, code, {
                level: 'public',
                progressCallback(progress) {
                    console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
                },
        })
        setShowSave(false)
        setShowLoader(false)
    }  

    const closeDialog = () =>{
    setOpenDialog(false)
    }

    return (
        <>
        <DialogSlide 
          open = {openDialog} 
          mode = 'save' 
          onConfirm = {save}
          onCancel = {closeDialog}
        />
        <Loader show = {showLoader}/>
        <AceEditor
            className= {classes.editor}
            placeholder=""
            mode= {props.mode}
            height = {!props.height?'90vh':props.height}
            theme={!props.theme?"solarized_dark":props.theme}
            name="edit"
            fontSize={16}
            showPrintMargin={false}
            showGutter={!props.gutter?true:props.gutter}
            highlightActiveLine={!props.line?true:props.line}
            value={code}
            onChange={onChange}
            setOptions={{
                enableLiveAutocompletion: true,
                showLineNumbers: true,
            }}
        />
        {showSave === true &&
        <Fab className= {classes.buttonFab} color="primary" aria-label="add" onClick={handleSave}> 
          <SaveOutlined className= {classes.fabIcon}/>
       </Fab>}
       </>
    )
}
