import React, {useContext} from "react";
import AuthContext from "../../context/AuthContext";
import AlertContext from "../../context/AlertContext";
import Alert from '@material-ui/lab/Alert';
import Snackbar from '@material-ui/core/Snackbar';
import useStyle from "./style";
import Home from '../../pages/Home/index'
import Data from '../../pages/Data/index'
import ConsolePage from '../../pages/Console/index'
import Quicksight from '../../pages/Quicksight/index'
import Workloads from '../../pages/Workloads/index'
import Organization from '../../pages/Organization/index'
import Analytics from '../../pages/Analytics/index'
import MLStudio from "../../pages/MLStudio/index";

export default React.memo(({indexList, searchField}) => {
  const classes = useStyle();
  const {user} = useContext(AuthContext);
  const {message, severity, visible, hideAlert} = useContext(AlertContext);


  return (
      <div>
        {
        // indexList === 0 ? <Home/>:
        // indexList === 1 ? <MLStudio/>:  
        indexList === 0 ? <Data/>:
        indexList === 1 ? <Quicksight/>:  
        //indexList === 2 ? <Quicksight/>: 
        //indexList === 5 ?  window.location.href = 'http://34.254.198.116:8888/lab?token=tyui9it8': 
        //indexList === 5 ? <MLStudio/>:
        // indexList === 5 ? <ConsolePage/>: 
        // indexList === 6 ? <Organization/>: 
        indexList}
        <Snackbar style={{maxWidth: 600}} open={visible} autoHideDuration={6000} onClose={hideAlert}>
          <Alert variant={"filled"} severity={severity} onClose={hideAlert}>
            {message}
          </Alert>
        </Snackbar>
      </div>
  );
});
