import { Translations } from "@aws-amplify/ui-components";

function authConfig() {

    return {
        background: 'https://www.martinezotero.com/images/EXPERTISE/MARTINEZ_OTERO_CONTRACT_Showroom_01.jpg'
    }
  }

function setSpanish(){

  return  {
        [Translations.SIGN_IN_HEADER_TEXT]: "Ribosomu",
        [Translations.USERNAME_LABEL]: 'Usuario *',
        [Translations.USERNAME_PLACEHOLDER]: 'Introduzca usuario',
        [Translations.PASSWORD_LABEL]: 'Contraseña *',
        [Translations.PASSWORD_PLACEHOLDER]: 'Introduzca contraseña',
        [Translations.FORGOT_PASSWORD_TEXT]: '¿Olvidó su contraseña?',
        [Translations.RESET_PASSWORD_TEXT]: 'Resetee su contraseña',
        [Translations.NO_ACCOUNT_TEXT]: '¿Sin cuenta?',
        [Translations.CREATE_ACCOUNT_TEXT]: 'Crear cuenta',
        [Translations.SIGN_IN_ACTION]: 'Accede'
      }

}

function setChinese() {

    return  {
        [Translations.SIGN_IN_HEADER_TEXT]: "ribosomu",
        [Translations.USERNAME_LABEL]: '用户 *',
        [Translations.USERNAME_PLACEHOLDER]: '输入用户',
        [Translations.PASSWORD_LABEL]: '密码 *',
        [Translations.PASSWORD_PLACEHOLDER]: '输入密码',
        [Translations.FORGOT_PASSWORD_TEXT]: '忘记密码?',
        [Translations.RESET_PASSWORD_TEXT]: '重设密码',
        [Translations.NO_ACCOUNT_TEXT]: '没有账户?',
        [Translations.CREATE_ACCOUNT_TEXT]: '创建账户',
        [Translations.SIGN_IN_ACTION]: '登入'
        }
  
  }

  function setEnglish() {

    return  {
        [Translations.SIGN_IN_HEADER_TEXT]: "ribosomu",
        }
  
  }

export {
    setSpanish,
    setChinese,
    setEnglish,
    authConfig
}