import {makeStyles} from '@material-ui/core/styles';
import style from './style';
import React from 'react';
import Chart from '../../components/Chart/index'
import {Grid, Paper} from '@material-ui/core';
import { API, graphqlOperation } from 'aws-amplify';
import * as queries from '../../graphql/queries';

const useStyles = makeStyles((theme) => (style));

export default function Home() {


    React.useEffect(() =>  {
    }, []);


    const classes = useStyles();
    const data1 = {
          
        series: [{
            data: [34, 44, 54, 21, 12, 43, 33, 23, 66, 66, 58]
        }],

        options: {
            chart: {
                type: 'histogram',
                height: 350,
                toolbar: {
                  show: false,
                }  
            },
            stroke: {
                curve: 'stepline',
            },
            dataLabels: {
                enabled: false
            },
            title: {
                text: 'Stepline Chart',
                align: 'left'
            },
            markers: {
                hover: {
                sizeOffset: 4
                }
            }
        }
    }

    const data2 = {
          
            series: [{
                name: 'series1',
                data: [31, 40, 28, 51, 42, 109, 100]
            }, {
                name: 'series2',
                data: [11, 32, 45, 32, 34, 52, 41]
            }],

            options: {
              chart: {
                height: 350,
                type: 'area',
                toolbar: {
                  show: false,
                }  
              },
              dataLabels: {
                enabled: false
              },
              stroke: {
                curve: 'smooth'
              },
              xaxis: {
                type: 'datetime',
                categories: ["2018-09-19T00:00:00.000Z", "2018-09-19T01:30:00.000Z", "2018-09-19T02:30:00.000Z", "2018-09-19T03:30:00.000Z", "2018-09-19T04:30:00.000Z", "2018-09-19T05:30:00.000Z", "2018-09-19T06:30:00.000Z"]
              },
              tooltip: {
                x: {
                  format: 'dd/MM/yy HH:mm'
                },
              },
            }
    }



  return (
    <div className={classes.root}>
              <Paper  className={classes.paper} > 
                <Chart options={data1.options} series={data1.series} type="bar" width= {'100%'} height={300} />
                </Paper> 
                <Paper  className={classes.paper} > 
                <Chart options={data2.options} series={data2.series} type="area" width= {'100%'} height={300} />
                </Paper > 
    </div>
  )
}
